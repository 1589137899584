/* light gray: #787878 */
/* bg gray: #f1f1ef */
/* bg blue: #00123E */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

* ::-moz-selection {
    background: #d2fdde;
    color: #000;
}

* ::selection {
    background: #d2fdde;
    color: #000;
}

html {
    font-size: 16px;
}

body {
    font-family: inherit;
    background: #ffffff;
    color: #000;
}

input,
select,
textarea,
button {
    font-family: inherit;
}

button {
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
}

ul {
    list-style: none;
}

a {
    color: #000;
    text-decoration: none;
}
